<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th>Invoice Date</th>
          <th>Invoice no</th>
          <th>Patient Name (ID)</th>
          <th>Investigations</th>
          <th v-for="groupName in groupNames">{{ groupName.name }}</th>
          <th class="text-right">Total Bill Amount</th>
          <th class="text-right">Referrer Discount</th>
          <th class="text-right">Hospital Discount</th>
          <th class="text-right">Net Collection</th>
          <th class="text-right">Discount Remarks</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in lists" :key="i">
          <td>{{ item.date }}</td>
          <td>{{ item.bill_number }}</td>
          <td>{{ item.contact_profile.full_name }} ({{ item.contact_profile.patient_id }})</td>
          <td>{{ getInvestigationName(item.general) }}</td>
          <td class="text-right" v-for="(groupName, groupId) in groupNames">
            {{ getGroupAmount(groupId, item.grouped_general) }}
          </td>
          <td class="text-right">{{ commaFormat(item.subtotal_amount) }}</td>
          <td class="text-right">{{ commaFormat(item.referrer_discount_amount) }}</td>
          <td class="text-right">{{ commaFormat(item.total_discount - item.referrer_discount_amount) }}</td>
           <td class="text-right">
            {{
              commaFormat(sumTotalSalesAmount(item.receipt_masters, 'paid_amount') - sumTotalSalesAmount(item.receipt_masters, 'refund_amount'))
            }}
          </td>
          <td class="text-right">{{ item.description }}</td>
        </tr>
        </tbody>

        <tbody>
        <tr class="bg bg-gray">
          <th class="text-center" colspan="4">Total</th>
          <th class="text-right" v-for="groupName in groupNames">{{ commaFormat(groupName.amount) }}</th>
          <th class="text-right">{{ commaFormat(getTotalAmount(lists)) }}</th>
          <th class="text-right">{{ commaFormat(getReferrerDiscountAmount(lists)) }}</th>
          <th class="text-right">{{ commaFormat(getTotalDiscount(lists) - getReferrerDiscountAmount(lists)) }}</th>
          <th class="text-right">{{
              commaFormat(((getTotalAmount(lists) - getTotalReturn(lists)) - (getTotalDiscount(lists) + sumTotalSalesAmount(ipdSettlementReceipts, 'discount_amount') - getTotalReturnDiscount(lists)) - getTotalDue() - getTotalIpdBill('sum_advance_adj')) + getTotalAdvance() + getTotalPreviousBill())
            }}</th>
          <th class="text-right"> </th>
        </tr>
        </tbody>

        <tbody>
        <tr>
          <th class="text-center" colspan="4">Referral %</th>
          <th class="text-right" v-for="groupName in groupNames">{{ commaFormat(groupName.referrer_percentage) }}%</th>
          <th class="text-right" colspan="5"> </th>
        </tr>
        <tr>
          <th class="text-center" colspan="4">Referral Fees </th>
          <th class="text-right" v-for="groupName in groupNames">{{ commaFormat(groupName.referrer_percentage > 0 ? (groupName.amount * groupName.referrer_percentage) / 100 : 0) }}</th>
          <th class="text-right">{{ commaFormat(getTotalAmountForAllGroups()) }}</th>
          <th class="text-right">-{{ commaFormat(getReferrerDiscountAmount(lists)) }}</th>
          <th class="text-right" colspan="3"> </th>
        </tr>
        <tr>
          <th class="text-center" colspan="4">Net Referral Fees</th>
          <th v-for="groupName in groupNames"></th>
          <th class="text-right"></th>
          <th class="text-right">{{ commaFormat(getTotalAmountForAllGroups() - getReferrerDiscountAmount(lists)) }}</th>
          <th class="text-right" colspan="3"> </th>
        </tr>
        </tbody>

      </table>
    </div>
  </div>
</template>

<script setup>
import figureFormatter from "@/services/utils/figureFormatter";
import {computed, onMounted} from "vue";
import handleCompany from "@/services/modules/company";
import { ref } from "vue";
import { useRoute } from "vue-router";
import pdfPrinter from '@/services/utils/pdf/DailySalesSummery';

const props = defineProps({
  lists: Array,
  groupNames: Object,
  finalTotal: Object,
  saleReturns: {
    type: Array,
    default: []
  },
  saleReturnPreviousBills: {
    type: Array,
    default: []
  },
  ipdSettlementReceipts: {
    type: Array,
    default: []
  },
  previousBillReceipts: {
    type: Array,
    default: []
  },
  advanceReceipts: {
    type: Array,
    default: []
  }
})

const {commaFormat} = figureFormatter()
const { fetchCompanyInfo } = handleCompany();
const { exportToPDF } = pdfPrinter();

const companyInfo = ref(null);
const route = useRoute();
// Group data by parent_name
const groupedData = computed(() => {
  const grouped = {};
  Object.values(props.groupNames).forEach(item => {
    const parentName = item.parent_name;
    if (!grouped[parentName]) {
      grouped[parentName] = [];
    }
    grouped[parentName].push(item);
  });
  return grouped;
});

const getGroupAmount = (groupId, grouped_general) => {
  const findGroupData = grouped_general.find((item) => item.product_group_id == groupId)
  if (findGroupData) {
    return commaFormat(findGroupData.total_amount)
  }
  return 0
}

const getInvestigationName = (items) => {
  return items.map(item => item.product.name).join(', ');
}

const getTotalAmount = (items) => {
  return items.reduce((total, i) => total + i.subtotal_amount, 0);
}

const getReferrerDiscountAmount = (items) => {
  return items.reduce((total, i) => total + i.referrer_discount_amount, 0);
}

const getTotalDiscount = (items) => {
  return items.reduce((total, i) => total + i.total_discount, 0);
}

const getTotalReturn = (items) => {
  const total = items.reduce((total, i) => total + i.sales_return_amount, 0)
  const saleReturn = props.saleReturns.reduce((total, i) => total + i.subtotal_amount, 0)
  const saleReturnPreviousBills = props.saleReturnPreviousBills.reduce((total, i) => total + i.subtotal_amount, 0)

  return total + saleReturn + saleReturnPreviousBills
}

const getTotalAmountForAllGroups = () => {
  return Object.values(props.groupNames)
      .filter(item => item.referrer_percentage > 0)
      .reduce((sum, item) => sum + ((item.amount * item.referrer_percentage) / 100), 0);
};

const getTotalReturnDiscount = (items) => {
  const total = items.reduce((total, i) => total + i.sales_return_discount_amount, 0)
  const saleReturn = props.saleReturns.reduce((total, i) => total + i.total_discount, 0)
  const saleReturnPreviousBills = props.saleReturnPreviousBills.reduce((total, i) => total + i.total_discount, 0)
  return total + saleReturn + saleReturnPreviousBills
}

const getTotalAdvance = () => {
  return props.advanceReceipts.reduce((total, i) => total + i.total_paid_amount, 0)
}

const getTotalPreviousBill = () => {
  const ipdTotal = props.ipdSettlementReceipts.reduce((total, i) => total + i.ipd_register.previous_sale_master_bill, 0)
  const previousTotal = props.previousBillReceipts.reduce((total, i) => total + i.total_paid_amount, 0)
  return ipdTotal + previousTotal
}

const getTotalReceipt = () => {
  let saleTotal = 0
  props.lists.forEach((item) => {
    saleTotal += sumTotalSalesAmount(item.receipt_masters, 'paid_amount')
  })

  const ipdTotal = props.ipdSettlementReceipts.reduce((total, i) => total + i.total_paid_amount, 0)
  const previousTotal = props.previousBillReceipts.reduce((total, i) => total + i.total_paid_amount, 0)
  const advanceTotal = props.advanceReceipts.reduce((total, i) => total + i.total_paid_amount, 0)
  return saleTotal + ipdTotal + previousTotal + advanceTotal
}

const getTotalRefund = () => {
  let saleTotal = 0
  props.lists.forEach((item) => {
    saleTotal += sumTotalSalesAmount(item.receipt_masters, 'refund_amount')
  })

  const saleReturn = props.saleReturns.reduce((total, i) => total + i.total_amount, 0)
  const saleReturnPreviousBills = props.saleReturnPreviousBills.reduce((total, i) => total + i.total_amount, 0)

  const ipdTotal = props.ipdSettlementReceipts.reduce((total, i) => total + i.refund_amount, 0)
  return saleTotal + saleReturn + saleReturnPreviousBills + ipdTotal
}

const getTotalDue = () => {
  let saleTotal = 0
  props.lists.forEach((item) => {
    saleTotal += (item.subtotal_amount - item.total_discount - sumTotalSalesAmount(item.receipt_masters, 'paid_amount')) - (item.sales_return_amount - item.sales_return_discount_amount - sumTotalSalesAmount(item.receipt_masters, 'refund_amount'))
  })

  let ipdTotal = 0
  props.ipdSettlementReceipts.forEach((item) => {
    ipdTotal += 0 - item.ipd_register.today_idp_bill
  })

  return saleTotal + ipdTotal
}

const getTotalIpdBill = (column) => {
  return props.ipdSettlementReceipts.reduce((total, i) => total + i.ipd_register[column], 0)
}

const sumTotalSalesAmount = (items, column) => {
  return items.reduce((total, i) => total + i[column], 0);
}

const getCompanyInfo = async () => {
  const companyId = route.params.companyId;
  await fetchCompanyInfo(companyId).then((res) => {
    companyInfo.value = res.data;
  })
}

const printSummery = () => {

  const summeryObj = {
    grossIncome: commaFormat(getTotalAmount(props.lists) - getTotalReturn(props.lists)),
    advMoneyReceived: commaFormat(getTotalAdvance()),
    advAdjustment: commaFormat(getTotalIpdBill('sum_advance_adj')),
    prevBillAdjustment: commaFormat(getTotalPreviousBill()),
    todayDue: commaFormat(getTotalDue()),
    netDiscount: commaFormat(getTotalDiscount(props.lists) + sumTotalSalesAmount(props.ipdSettlementReceipts, 'discount_amount') - getTotalReturnDiscount(props.lists)),
    receivableAdjustment: commaFormat((getTotalAdvance()+getTotalPreviousBill()) - (getTotalIpdBill('sum_advance_adj') + getTotalDue() + getTotalDiscount(props.lists) + sumTotalSalesAmount(props.ipdSettlementReceipts, 'discount_amount') - getTotalReturnDiscount(props.lists))),
    netCollection: commaFormat(((getTotalAmount(props.lists) - getTotalReturn(props.lists)) - (getTotalDiscount(props.lists) + sumTotalSalesAmount(props.ipdSettlementReceipts, 'discount_amount') - getTotalReturnDiscount(props.lists)) - getTotalDue() - getTotalIpdBill('sum_advance_adj')) + getTotalAdvance() + getTotalPreviousBill()),
    receivedAmount: commaFormat(getTotalReceipt()),
    refundAmount: commaFormat(getTotalRefund()),
    check: commaFormat((((getTotalAmount(props.lists) - getTotalReturn(props.lists)) - (getTotalDiscount(props.lists) + sumTotalSalesAmount(props.ipdSettlementReceipts, 'discount_amount') - getTotalReturnDiscount(props.lists)) - getTotalDue() - getTotalIpdBill('sum_advance_adj')) + getTotalAdvance() + getTotalPreviousBill()) + getTotalRefund() - getTotalReceipt()),
    groupedData: groupedData.value
  };
  
  exportToPDF(companyInfo.value, summeryObj);
}

onMounted(() => {
  getCompanyInfo();
})
</script>

<style scoped>
.report-title {
  display: flex;
  align-items: center;
}
.report-title h4 {
  margin-right: 3%;
}
.report-title button {
  margin-top: 1%;
}
</style>
