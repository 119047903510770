import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useStore } from "vuex";
import figureFormatter from "@/services/utils/figureFormatter";
import pdfHeaderFooter from '@/services/utils/pdf/pdfHeaderFooter';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}
const pdfPrinter = () => {
    const store = useStore();
    const {commaFormat} = figureFormatter();
    const {getHeader, getFooter} = pdfHeaderFooter();

    const userName = store.state.user.user.name;

    const exportToPDF = (company, lists,groupNames,finalTotal) => {
        var doc = {
            pageSize: 'A4',
            pageMargins: [10, 100, 10, 80],
            header: getHeader(company),
            footer: getFooter(userName),
            content: getContent(lists,groupNames),

            styles: {
                header: {
                    fontSize: 20,
                }
            },

            defaultStyle: {
                color: 'black',
                fontSize: 7,
                font: 'SulaimanLipi'
            },

            info: {
                title: 'Investigation Incentive'
            }
        }

        pushIntoTable(doc, lists, groupNames, finalTotal)

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (lists,groupNames) => {
        return [
            {
                text: 'Investigation Incentive',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 5, 5],
                bold: true,
                color: 'black',
                fontSize: 12,
                decoration: 'underline'
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    body: [],
                    widths: getTableWidths(groupNames)
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
                }
            },
        ]
    }

    const getTableWidths = (groupNames) => {
        let widths = ['10%', '10%'];
        const groupArray = Array.isArray(groupNames) ? groupNames : Object.values(groupNames);

        if (groupArray.length > 0) {
            const dynamicWidth = 52 / groupArray.length;
            groupArray.forEach(() => {
                widths.push(`${dynamicWidth}%`);
            });
        }
        widths = widths.concat(['7%', '7%', '7%', '7%']);

        return widths;
    };

    const pushIntoTable = (doc, lists,groupNames,finalTotal) => {
         const tableItems = lists;
        const tableHeader = getTableHeader(groupNames);
        doc.content[1].table.body.push(tableHeader);

        tableItems.forEach(item => {
            const rowData = getRowData(item, groupNames);
            return doc.content[1].table.body.push(rowData);
        });
        const getLastRow = getTotal(groupNames,finalTotal)
        doc.content[1].table.body.push(getLastRow);

        const getReferral = getReferralPercentage(groupNames)
        doc.content[1].table.body.push(getReferral);

        const getReferralFees = getReferralFeesData(groupNames,finalTotal)
        doc.content[1].table.body.push(getReferralFees);

        const getNetReferralFees = getNetReferralFeesData(groupNames,finalTotal)
        doc.content[1].table.body.push(getNetReferralFees);
    }

    const getRowData = (item, groupNames) => {
        const tableRow = [
            {text: item.date + ' (' + item.bill_number + ')'},
            {text: item.contact_profile.full_name + ' (' + item.contact_profile.patient_id + ')' },
        ]

        Object.entries(groupNames).forEach(([groupId, group]) => {
            const findGroupData = item.grouped_general.find((g) => g.product_group_id == groupId);
            const amount = findGroupData ? findGroupData.total_amount : 0;
            tableRow.push({ text: amount, alignment: 'right' });
        });

        tableRow.push(
            { text: commaFormat(item.subtotal_amount), alignment: 'right' },
            { text: commaFormat(item.referrer_discount_amount), alignment: 'right' },
            { text: commaFormat(item.total_discount - item.referrer_discount_amount), alignment: 'right' },
            { text: commaFormat(item.receipt_amount - item.refund_amount), alignment: 'right' },
        );
        return tableRow
    }

    const getTableHeader = (groupNames) => {
        const tableHeader = [
            { text: 'Invoice Date / NO' },
            { text: 'Patient Name (ID)' },
        ];
        const groupArray = Array.isArray(groupNames) ? groupNames : Object.values(groupNames);
        groupArray.forEach(group => {
            tableHeader.push({ text: group.name, alignment: 'right' });
        });
        tableHeader.push(
            { text: "Bill Amount", alignment: 'right' },
            { text: "Referrer Discount", alignment: 'right' },
            { text: "Hospital Discount", alignment: 'right' },
            { text: "Net Collection", alignment: 'right' },
        );

        return tableHeader;
    };

    const getTotal = (groupNames, finalTotal) => {
        const lastRow = [
            { text: 'Total', alignment: 'right', colSpan: 2 },
            {}
        ];

        const groupArray = Array.isArray(groupNames) ? groupNames : (groupNames ? Object.values(groupNames) : []);
        groupArray.forEach(group => {
            lastRow.push({ text: group.amount ? commaFormat(group.amount) : '0', alignment: 'right' });
        });

        lastRow.push(
            { text: commaFormat(finalTotal.bill), alignment: 'right' },
            { text: commaFormat(finalTotal.referrer_discount_amount), alignment: 'right' },
            { text: commaFormat(finalTotal.bill_discount - finalTotal.referrer_discount_amount), alignment: 'right' },
            { text: commaFormat(
                    ((finalTotal.bill - finalTotal.sales_return) -
                        (finalTotal.bill_discount + finalTotal.ipd_settl_discount - finalTotal.sales_return_discount)
                        - finalTotal.due - finalTotal.advance_adj) +
                    finalTotal.advance + finalTotal.previous_bill
                ), alignment: 'right' }
        );
        const expectedColumnCount = 6 + groupArray.length;
        while (lastRow.length < expectedColumnCount) {
            lastRow.push({ text: '', alignment: 'right' });
        }

        return lastRow;
    };

    const getReferralPercentage = (groupNames) => {
        const lastRow = [
            { text: 'Referral %', alignment: 'right', colSpan: 2 },
            {}
        ];
        const groupArray = Array.isArray(groupNames) ? groupNames : (groupNames ? Object.values(groupNames) : []);

        groupArray.forEach(group => {
            lastRow.push({ text: group.referrer_percentage ? commaFormat(group.referrer_percentage) + '%' : '0', alignment: 'right' });
        });

        lastRow.push(
            {},
            {},
            {},
            {},
        );
        const expectedColumnCount = 6 + groupArray.length;
        while (lastRow.length < expectedColumnCount) {
            lastRow.push({ text: '', alignment: 'right' });
        }

        return lastRow;
    };

    const getReferralFeesData = (groupNames,finalTotal) => {
        const lastRow = [
            { text: 'Referral Fees', alignment: 'right', colSpan: 2 },
            {}
        ];
        const groupArray = Array.isArray(groupNames) ? groupNames : (groupNames ? Object.values(groupNames) : []);

        groupArray.forEach(group => {
            lastRow.push({ text: group.referrer_percentage > 0 ? commaFormat((group.amount * group.referrer_percentage) / 100) : '0', alignment: 'right' });
        });

        lastRow.push(
            {text: commaFormat(totalAmountForAllGroups(groupNames)), alignment: 'right'},
            {text: - commaFormat(finalTotal.referrer_discount_amount), alignment: 'right'},
            {},
            {},
        );
        const expectedColumnCount = 6 + groupArray.length;
        while (lastRow.length < expectedColumnCount) {
            lastRow.push({ text: '', alignment: 'right' });
        }

        return lastRow;
    };

    const getNetReferralFeesData = (groupNames,finalTotal) => {
        const lastRow = [
            { text: 'Net Referral Fees', alignment: 'right', colSpan: 2 },
            {}
        ];
        const groupArray = Array.isArray(groupNames) ? groupNames : (groupNames ? Object.values(groupNames) : []);

        groupArray.forEach(group => {
            lastRow.push({ text: '', alignment: 'right' });
        });

        lastRow.push(
            {},
            {text: commaFormat(totalAmountForAllGroups(groupNames) - finalTotal.referrer_discount_amount), alignment: 'right'},
            {},
            {},
        );
        const expectedColumnCount = 6 + groupArray.length;
        while (lastRow.length < expectedColumnCount) {
            lastRow.push({ text: '', alignment: 'right' });
        }

        return lastRow;
    };

    const totalAmountForAllGroups = (groupNames) =>  {
        return Object.values(groupNames)
            .filter(group => group.referrer_percentage > 0)
            .reduce((sum, group) => sum + (group.amount * group.referrer_percentage) / 100, 0);
    }

    return {
        exportToPDF
    }

}

export default pdfPrinter;