<template>
  <div class="card">

    <div class="bg-blue-light p-1">
      <TitleButton
          :showBtn="false"
          :showAddNew="false"
          title="Investigation Incentive Report"
      />
    </div>

    <div class="my-2 px-2">
      <DateQuerySetter
          btnTitle="Go"
          @onClickGo="getReport"
      >
        <div class="col-12 col-sm-6 col-md-3 col-lg-2">
          <label
              for="colFormLabel"
              class="col-form-label"
          >
            Invoice Type
          </label>
          <v-select
              multiple
              placeholder="Select Bill Type"
              v-model="billType"
              :options="billTypeList"
              label="label"
              :reduce="label => label.value"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-3 col-lg-2">
          <label
              for="colFormLabel"
              class="col-form-label"
          >
            Referrer
          </label>
          <AsyncSelect
              placeholder="Select Referrer"
              v-model="referrer"
              :api-service="fetchContactProfiles"
              :additional-query="{ type: 'agent' }"
              :format-label="formatPatientLabel"
              :reduce="referrer => referrer.id"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-3 col-lg-3">
          <label
              for="colFormLabel"
              class="col-form-label"
          >
            Consultant/Doctor
          </label>
          <AsyncSelect
              placeholder="Select Consultant/Doctor"
              v-model="serviceResource"
              :api-service="fetchServiceList"
              :additional-query="{ resource_type: 'human_resources' }"
              :format-label="formatPatientLabel"
              :reduce="referrer => referrer.id"
          />
        </div>

      </DateQuerySetter>
    </div>

      <div class="row mx-2">
        <div class="col-12 d-flex justify-content-end">
          <button
              @click="goToPrint"
              class="btn btn-outline-secondary btn-sm mb-2 mx-2"
          >
            Print
          </button>
          <button
              @click="exportReport"
              class="btn btn-outline-secondary btn-sm mb-2"
          >Export
          </button>
        </div>
      </div>

    <div class="col-12 px-2">
      <ListTable
          :lists="lists"
          :groupNames="groupNames"
          :finalTotal="finalTotal"
          :saleReturns="saleReturns"
          :saleReturnPreviousBills="saleReturnPreviousBills"
          :ipdSettlementReceipts="ipdSettlementReceipts"
          :previousBillReceipts="previousBillReceipts"
          :advanceReceipts="advanceReceipts"
      />
    </div>
    <div class="mb-2"></div>
    <Loader v-if="loading"/>
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import {useRoute, useRouter} from 'vue-router';
import ListTable from '@/components/molecule/company/hospital/hospital-admin/InvestigationIncentiveReportTable.vue';
import handleHospitalReport from "@/services/modules/hospital/report";
import {computed, inject, onMounted, ref} from "vue";
import Loader from "@/components/atom/LoaderComponent.vue";
import {useStore} from "vuex";
import handleInventory from "@/services/modules/inventory";
import handleImportTracker from "@/services/modules/procurement/importTracker";
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleContact from '@/services/modules/contact'
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleHospital from '@/services/modules/hospital'
import pdfPrinter from '@/services/utils/pdf/investigationIncentive';
import handleCompany from "@/services/modules/company";

const {fetchInvestigationIncentiveReport} = handleHospitalReport()
const {fetchServiceList} = handleHospital()
const {fetchHome} = handleInventory()
const {fetchContactProfiles} = handleContact()
const { formatPatientLabel } = useAsyncDropdownHelper()
const { exportToPDF } = pdfPrinter();
const { fetchContacts } = handleImportTracker();
const { fetchCompanyInfo } = handleCompany();

const showError = inject('showError');
const showSuccess = inject('showSuccess');
const store = useStore()
const route = useRoute();
const router = useRouter();
const loading = ref(false);
const business = ref([])
const lists = ref([])
const groupNames = ref({})
const finalTotal = ref({})
const saleReturns = ref([])
const saleReturnPreviousBills = ref([])
const ipdSettlementReceipts = ref([])
const previousBillReceipts = ref([])
const advanceReceipts = ref([])
const date = ref()
const token = localStorage.getItem('token');
const billTypeList = ref([])
const billType = ref(null)
const referrer = ref(null)
const serviceResource = ref(null)
const contactList = ref([])
const companyInfo = ref(null)

//computed
const companyId = computed(() => route.params.companyId);
const start = computed(() => route.query.start);
const end = computed(() => route.query.end);
//methods
const getQuery = () => {
  let query = '?company_id=' + companyId.value
  if(billType.value && billType.value.length > 0) query += `&sale_type=${billType.value}`
  if (start) query += '&start_date=' + start.value
  if (end) query += '&end_date=' + end.value
  if(referrer.value) query += '&referrer_id=' + referrer.value;
  if(serviceResource.value) query += '&service_resource_id=' + serviceResource.value;
  return query
};
const getReport = () => {
  fetchInvestigationIncentiveReport(getQuery()).then(({status, ...rest}) => {
    if (!status) {
      lists.value = []
      return groupNames.value = {}
    }

    lists.value = rest.data
    groupNames.value = rest.group_names
    finalTotal.value = rest.final_total
  }).catch((err) => {
    loading.value = false
  }).finally(() => {
    loading.value = false
  })
};

const getInvoiceType = () => {
  fetchHome().then(res => {
    if (res.data) {
      for (const value of Object.keys(res.data.hospital_invoice_types)) {
        billTypeList.value.push({
            value: value,
            label: res.data.hospital_invoice_types[value]
        })
      }
    }
  }).catch(e => showError('Something went wrong'))
}

const getContacts = () => {
  fetchContacts('?company_id=' + companyId.value).then(res => {
    if (res.data) {
      contactList.value = res.data;
    }
  }).catch(e => showError('Something went wrong'))
}

const goToPrint = async () =>  {
  fetchInvestigationIncentiveReport(getQuery()).then(({status, ...rest}) => {
    if (!status) {
      lists.value = []
      return groupNames.value = {}
    }
    lists.value = rest.data
    groupNames.value = rest.group_names
    finalTotal.value = rest.final_total
    exportToPDF(companyInfo.value, lists.value,groupNames.value,finalTotal.value)
  }).catch((err) => {
    console.log(err)
    loading.value = false
  }).finally(() => {
    loading.value = false
  })
};

const exportReport = () => {
  let query = getQuery();
  let url = `${process.env.VUE_APP_BASE_URL}/export/hospital/investigation-incentive${query}&_token=${token}`;
  let a = document.createElement('a');
  a.setAttribute('href', url);
  a.click();
}

const getCompanyInfo = async () =>  {
  try{
    let res = await fetchCompanyInfo(companyId.value)
    if (!res.status) {
      this.showError(res.message);
      return;
    }
    companyInfo.value = res.data;
  } catch (err) {
    if (!err.response) {
      showError("Something is wrong. Check your connectivity!!");
    }
    if (err.response) {
      showError(err.response.message);
    }
  }
}

onMounted(async () => {
  start.value = route.query.start_date
  end.value = route.query.end_date
  getInvoiceType()
  getContacts()
  await getCompanyInfo()
})
</script>
